<template>
  <div id="login" class="loginBg">
    <div class="login-title"></div>
    <div class="loginware">
      <div class="login-box">
        <div class="home_logo" @click="$router.push('/')">
          <img src="@/assets/images/logo.png" alt="" />
        </div>
        <div class="loginboard">
          <div class="loginboard-form">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="0"
            >
              <div class="login-name">企业账号登录</div>
              <el-form-item label="" prop="username">
                <el-input
                  v-model="ruleForm.username"
                  placeholder="输入账号/手机号/邮箱"
                  @keyup.enter.native="submitForm('ruleForm')"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input
                  type="password"
                  v-model="ruleForm.password"
                  @keyup.enter.native="submitForm('ruleForm')"
                  placeholder="输入密码"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="" prop="checkCode">
                <div class="identifyBlock">
                  <el-input
                    v-model="ruleForm.checkCode"
                    placeholder="图形验证码"
                    @keyup.enter.native="submitForm('ruleForm')"
                  ></el-input>
                  <div class="valid" @click="refreshCode">
                    <s-identify :identifyCode="identifyCode"></s-identify>
                  </div>
                </div>
              </el-form-item> -->
              <el-form-item label-width="0" class="">
                <el-button
                  type="primary"
                  class="subbtn"
                  @click="submitForm('ruleForm')"
                  @keyup.enter.native="submitForm('ruleForm')"
                  onkeydown="myFunction()"
                  >立即登录
                </el-button>
              </el-form-item>
              <el-row class="text-right">
                <el-button
                  type="text"
                  @click="
                    $router.push({ path: '/forget', query: { type: 'find' } })
                  "
                  >忘记密码？</el-button
                >
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="addr">
      <p>公司地址：北京市东城区朝阳门银河soho C座7层30706室</p>
      <div class="comp">
        <p>Copyright 2016-2022 支出宝（北京）信息技术有限公司</p>
        <p>京ICP备 16061542号-11 京公网安备 11010502040802号</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { getIsLogin } from 'src/util/js/headerToken'
export default {
  data() {
    let checkname = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入账号/手机号/邮箱'))
      } else if (/[\u4E00-\u9FA5]/g.test(value)) {
        callback(new Error('格式错误!'))
      } else {
        callback()
      }
    }
    let checkpassword = (rule, value, callback) => {
      if (value === '') {
        callback('密码不能为空')
      } else {
        callback()
      }
    }
    return {
      identifyCode: 'abcd',
      ruleForm: { username: '', password: '' },
      rules: {
        username: [
          { required: true, message: '请输入账号/手机号/邮箱' }
          // { validator: checkname, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码' },
          { validator: checkpassword, trigger: 'blur' }
        ]
      },
      isLogin: getIsLogin()
    }
  },
  created() {
    if (this.isLogin) {
      this.$router.push('/home')
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.isLogin) {
            let loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$store
              .dispatch('user/getlogin', this.ruleForm)
              .then(res => {
                loading.close()
                if (res.code === 200) {
                  this.$message({
                    type: 'success',
                    message: '登录成功！'
                  })
                  // 防止登陆后字典接口未返回导致表格加载出错
                  if (!localStorage.getItem('DICTIONARY')) {
                    this.$store.dispatch('dictionary/getDictionaryAll')
                  }
                  this.$store.dispatch('user/getInfo')
                  this.$store.dispatch('menu/setMenuList')
                } else {
                  this.refreshCode()
                  this.$message({
                    type: 'error',
                    message: res.desc
                  })
                }
              })
              .catch(() => {
                loading.close()
                this.formData = this.$options.data().formData
              })
          } else {
            // loading.close();
            this.$router.push('/home')
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.loginBg {
  position: relative;
  min-height: 100vh;
  // background: url('../../assets/images/bg5.jpg') no-repeat;
  background-size: 100%;
  background-size: cover;
  user-select: none;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.22);
  // }
}
.loginware {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
  min-height: 640px;
  opacity: 0.8;
}
.login-box {
  width: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 580px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  .home_logo {
    width: 130px;
    height: 130px;
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -65px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 80px;
      height: 80px;
    }
  }
}
.login-title {
  position: absolute;
  top: 0;
  left: 0;
  height: 128px;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 100px;
  font-weight: 500;
  color: #ffffff;
  img {
    width: 145px;
    margin-right: 20px;
  }
}
.loginboard {
  // width: 90%;
  // max-width: 350px;

  .subbtn {
    margin-top: 30px;
    width: 100%;
    font-size: 16px;
    letter-spacing: 2px;
    // background-color: $themeColorBtn;
    // border-color: $themeColorBtn;
    border-color: #ffffff;
    background: transparent;
  }
}
.loginboard-title {
  padding-bottom: 40px;
  font-size: 24px;
  color: #fff;
  img {
    display: inline-block;
    margin-right: 16px;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    border-radius: 20px;
  }
}
.success-form {
  width: 500px;
  height: 550px;
  border-radius: 4px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginboard-form {
  width: 500px;
  box-sizing: border-box;
  padding: 10px 30px 20px;
  background: rgba(45, 52, 54, 1);
  height: 550px;
  min-height: 480px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  // border-top-left-radius: 10px;
  // border-bottom-left-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  /deep/.el-form {
    width: 80%;
    height: 70%;
  }
}
.login-name {
  text-align: center;
  font-weight: bold;
  margin: 0;
  color: beige;
  font-size: 30px;
  margin-bottom: 10px;
}
.identifyBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 43px;
  .valid {
    position: relative;
    margin-top: 1px;
    margin-left: 5px;
  }
}
.addr {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  p {
    padding: 0 0 10px;
    margin: 0;
  }
  .comp {
    width: 650px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 20px;
    p {
      line-height: 1;
      padding: 0;
    }
  }
}
/deep/ .el-input__inner {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  -webkit-text-fill-color: #fff; /* 设置字体颜色 */
  font-size: 18px;
  &::placeholder {
    background: transparent;
    color: #fff !important;
    font-size: 15px;
  }
  &:-webkit-autofill {
    background: transparent;
    color: #fff !important;
    transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
  }
}
</style>
